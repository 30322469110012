<!--
 * @Author: Link
 * @Date: 2020-12-31 14:03:24
 * @LastEditTime: 2021-01-20 16:45:46
-->
<template>
  <el-row style="width: 100%" type="flex" justify="center" align="middle">
    <el-col :sm="12" :md="10">
      <div class="container-wrapper">
        <div class="left">
          <img src="@/asset/login_img.jpg" alt="" />
        </div>
        <div class="right">
          <div class="panel">
            <div class="logo">
              <img src="@/asset/logo_head.png" alt="" />
            </div>
            <el-form :model="form" :rules="rules" ref="form" class="loginFrom">
              <el-form-item prop="userName">
                <el-input
                  class="input"
                  prefix-icon="el-icon-user"
                  placeholder="请输入用户名称"
                  v-model.trim="form.userName"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  class="input"
                  type="password"
                  prefix-icon="el-icon-lock"
                  v-model.trim="form.password"
                  placeholder="请输入密码"
                  autocomplete="off"
                  @keyup.enter.native="submitForm"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="submit" @click="submitForm" style="width: 100%">登录</el-button>
              </el-form-item>
            </el-form>
            <div class="copyRight">
              <a href="http://www.boomsj.com/" target="blank" class="company">Powered By 广州大事件网络科技有限公司</a>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        userName: '',
        password: ''
      },
      rules: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' }
          // { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
          // { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    submitForm() {
      this.$refs.form.validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return
        }
        this.$store
          .dispatch('user/loginByPassword', this.form)
          .then(res => {
            this.$router.push('/malls/overview')
            // if (this.$route.query.c) {
            //   this.$router.push(this.$route.query.c)
            // } else {
            //   this.$router.push('/malls/overview')
            // }
          })
          .catch(err => {
            this.$message.error(err.msg || err.message || err)
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container-wrapper {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);
  position: relative;
  animation: fadeInDown 1s forwards;
  .left {
    flex: 3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
  .right {
    flex: 2;
    padding: 30px;
    animation: fadeIn 2s forwards;
    animation-delay: 1s;
    background: #fff;
    opacity: 0;
    .panel {
      width: 310px;
      margin: 0 auto;
      .logo {
        width: 210px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        img {
          width: 100%;
        }
      }
      /deep/.el-input__inner {
        width: 100%;
        max-width: 300px;
        min-width: 200px;
        background-color: #f5f5f5 !important;
        border: none;
      }
      .input {
        width: 300px;
      }
      .submit {
        background: red;
        margin-top: 20px;
        padding: 12px;
        border: none;
      }
      .copyRight {
        text-align: center;
        color: #a0a0a0;
        font-size: 12px;
        margin-top: 50px;
        margin-bottom: 40px;
        a {
          color: #a0a0a0;
        }
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInDown {
  0% {
    transform: translateY(-60%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
