<route>
{
  "meta": {
    "allowGuest": true
  }
}
</route>

<template>
  <div class="page animation-background">
    <!-- <li v-for="(i, n) in 4" :key="'big-' + n" :class="`bubble-${i + 1}`" :style="bigNode()" class="bubble">
      <img src="@/asset/big_logo.png" alt="" />
    </li> -->
    <li v-for="(i, n) in 14" :key="'logo-' + n" :class="`bubble-${i + 5}`" :style="smallNode()" class="bubble">
      <img src="@/asset/logo.png" />
    </li>

    <com-loginCard />

    <el-dialog :visible="isCheatCodeMatch" title="选择租户" :show-close="false">
      <div class="tenantPicker">
        <el-button
          type="success"
          v-for="i in tenantList"
          :key="i.id"
          style="margin: 10px"
          @click="$store.dispatch('global/changeTenant', i.id)"
        >
          【{{ i.name }}】 {{ i.id }}
        </el-button>
        <el-button type="info" @click="handleTenant">其他</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { log } from '@/util/log'
import { mapGetters, mapActions } from 'vuex'
import comLoginCard from './__com__/loginCard'
export default {
  layout: 'fullPage',
  components: { comLoginCard },
  data() {
    return {
      tenantList: [
        { id: 1, name: '贪吃dev版' },
        { id: 2, name: '吃团团dev版' },
        { id: 1001, name: '灰度版' },
        { id: 1688, name: '贪吃虎' },
        { id: 1888, name: '吃团团正式版' },
        { id: 2001, name: '正式租户' }
      ]
    }
  },
  computed: {
    ...mapGetters({ isCheatCodeMatch: 'keyboard/isCheatCodeMatch' }),
    bigNode: () => () => {
      const size = Math.floor(Math.random() * 3) + 830
      const time = Math.floor(Math.random() * 6) + 4
      return { width: size + 'px', height: size + 'px', animationDuration: time + 's' }
    },
    smallNode: () => () => {
      const size = Math.floor(Math.random() * 80) + 20
      const left = Math.floor(Math.random() * 80) + 10
      const bottom = Math.floor(Math.random() * 200) + 50
      const animation = [
        'linear-bubble',
        'north-east-bubble',
        'north-east-bubble2',
        'north-west-bubble',
        'north-west-bubble2'
      ][Math.floor(Math.random() * 5)]
      const time = Math.floor(Math.random() * 6) + 4
      return {
        width: size + 'px',
        height: size + 'px',
        left: left + '%',
        bottom: bottom + 'px',
        animationDuration: time + 's'
        // animation: `${animation} ${time}s infinite linear;`
      }
    }
  },
  methods: {
    handleTenant() {
      this.$prompt('请输入租户ID', '提示', {
        inputPattern: /^\d{4}$/,
        inputErrorMessage: '租户ID不正确'
      }).then(({ value }) => {
        this.$store.dispatch('global/changeTenant', Number(value))
        this.$message({
          type: 'success',
          message: '已切换租户ID: ' + value
        })
      })
    }
  },
  mounted() {
    if (window.location.host !== 'admin.mall.tanchi.shop') {
      this.$store.dispatch('keyboard/initKeyboardListener')
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.icon {
  width: 5em;
  height: 5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.container-wrapper {
  background: #fff;
  position: absolute;
  border-radius: 5px;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  padding: 18px 28px 28px 28px;
  -webkit-box-shadow: -4px 7px 46px 2px rgba(0, 0, 0, 0.1);
  box-shadow: -4px 7px 46px 2px rgba(0, 0, 0, 0.1);
}

.name {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
}

.company {
  font-size: 9px;
  color: gray;
}

.logo {
  text-align: center;
}

.copyRight {
  text-align: center;
}

.animation-background {
  background: linear-gradient(-45deg, #ff2240, #ff3a46, #d90c24, #ff524c);
  background-size: 400% 400%;
  position: absolute;
  animation: Gradient 10s ease infinite;
  -webkit-animation: Gradient 10s ease infinite;
  -moz-animation: Gradient 10s ease infinite;
  width: 100%;
  height: 100%;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.bubble {
  list-style-type: none;
  border-radius: 50%;
  position: absolute;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  img {
    width: 100%;
    height: 100%;
  }
  &-2 {
    right: -300px;
    bottom: -300px;
    animation-name: big-bubble;
  }
  &-3 {
    right: -350px;
    top: -50px;
    border-color: rgba(255, 255, 255, 0);
    animation-name: big-bubble;
  }
  &-4 {
    left: -500px;
    bottom: -300px;
    border-color: rgba(255, 255, 255, 0);
    animation-name: big-bubble;
  }
  &-5 {
    left: -300px;
    top: -300px;
    animation-name: big-bubble;
  }
  &-6 {
    animation-name: linear-bubble;
  }
  &-7 {
    animation-name: north-east-bubble;
  }
  &-8 {
    animation-name: north-east-bubble;
  }
  &-9 {
    animation-name: north-east-bubble2;
  }
  &-10 {
    animation-name: north-east-bubble2;
  }
  &-11 {
    animation-name: north-east-bubble;
  }
  &-12 {
    animation-name: north-east-bubble2;
  }
  &-13 {
    animation-name: north-east-bubble2;
  }
  &-14 {
    animation-name: north-east-bubble;
  }
  &-15 {
    animation-name: north-west-bubble;
  }
  &-15 {
    animation-name: north-west-bubble;
  }
  &-16 {
    animation-name: north-west-bubble2;
  }
  &-17 {
    animation-name: north-west-bubble;
  }
  &-18 {
    animation-name: north-west-bubble2;
  }
  &-19 {
    animation-name: north-west-bubble2;
  }
}

@keyframes linear-bubble {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-400px);
    opacity: 0;
  }
}
@keyframes big-bubble {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-10%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes north-east-bubble {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    // transform: translate();
    opacity: 1;
  }
  100% {
    transform: translate(100px, -500px);
    opacity: 0;
  }
}
@keyframes north-east-bubble2 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    // transform: translate();
    opacity: 1;
  }
  100% {
    transform: translate(400px, -500px);
    opacity: 0;
  }
}
@keyframes north-west-bubble {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    // transform: translate();
    opacity: 1;
  }
  100% {
    transform: translate(-400px, -500px);
    opacity: 0;
  }
}
@keyframes north-west-bubble2 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    // transform: translate();
    opacity: 1;
  }
  100% {
    transform: translate(-200px, -500px);
    opacity: 0;
  }
}
</style>
